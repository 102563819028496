import React, { useEffect, useState } from "react";
import "./PlaceAbsence.scss";
import axios from "axios";
import right from "../../../assets/images/icons/right-arrow.svg";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

const PlaceAbsence = () => {
  const [data, setData] = useState([]);
  const [dataToAdd, setDataToAdd] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  let activateMainCourses = async (data) => {
    let MainCourseArray = [];

    Object.values(data)?.forEach((item, index) => {
      if (Object.keys(data)[index] === "Main Course") {
        Object.values(item).forEach((innerItem) => {
          MainCourseArray.push(Number(innerItem.course_id));
        });
      }
    });

    MainCourseArray = MainCourseArray.filter(
      (item) => !dataToAdd.includes(item)
    );

    setDataToAdd(dataToAdd.concat(MainCourseArray));
  };

  const getdata = async () => {
    const login_cookie = localStorage.getItem("session_user_login_cookie");
    const login_user_id = localStorage.getItem("session_user_id");
    const login_action_type = localStorage.getItem("session_user_type");

    const api = `https://www.api.salsaworldstandardclass.com/function.php/?login_cookie=${login_cookie}&login_user_id=${login_user_id}&login_action_type=${login_action_type}&f=getCourseBySchoolAndLevelIdDev&user_id=${login_user_id}&status=1`;
    await axios.post(api).then(async (resp) => {
      setData(resp.data);
      activateMainCourses(resp.data);
    });
  };

  const placeAbsence = async () => {
    const formData = new FormData();
    formData.append("sel", dataToAdd.toString());
    formData.append("start_date", startDate);
    formData.append("end_date", endDate);
    formData.append("user_id", localStorage.getItem("session_user_id"));
    formData.append("school_id", localStorage.getItem("session_school_id"));
    formData.append("user_type", localStorage.getItem("session_user_type"));
    formData.append("feedback", "");

    const login_cookie = localStorage.getItem("session_user_login_cookie");
    const login_user_id = localStorage.getItem("session_user_id");
    const login_action_type = localStorage.getItem("session_user_type");

    if (dataToAdd.length === 0) {
      toast("Please Add Course");
    }
    if (startDate.length < 9) {
      toast("Please Select Start Date");
    }
    if (endDate.length < 9) {
      toast("Please Select End Date");
    }

    let dateFirst = new Date(startDate);
    let dateSecond = new Date(endDate);

    const currentDate = new Date();

    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");

    const todaysFormatedDate = new Date(`${year}-${month}-${day}`);

    if (dateFirst > dateSecond) {
      toast("End Date Is Before The Start Date. Please Select Date Properly.");
    } else if (dateFirst < todaysFormatedDate) {
      toast("You Can't Place Absence For Date Before Today");
    } else {
      if (
        dataToAdd.length !== 0 &&
        startDate.length > 2 &&
        endDate.length > 2
      ) {
        const url = `https://www.api.salsaworldstandardclass.com/function.php/?login_cookie=${login_cookie}&login_user_id=${login_user_id}&login_action_type=${login_action_type}&f=setMultiCourseClassAttendance&status=1`;
        if (new Date().getHours() < 16) {
          await axios.post(url, formData).then((resp) => {
            if (resp.data.status) {
              toast("Absence Added Successfully");
              setDataToAdd([]);
              setStartDate("");
              setEndDate("");
            }

            if (!resp.data.status) {
              toast(
                "Please check with your teacher about absences over 3 weeks. Thank you"
              );
            }
          });
        } else if (
          new Date().getHours() >= 16 &&
          dateFirst > todaysFormatedDate
        ) {
          await axios.post(url, formData).then((resp) => {
            if (resp.data.status) {
              toast("Absence Added Successfully");
              setDataToAdd([]);
              setStartDate("");
              setEndDate("");
            }

            if (!resp.data.status) {
              toast(
                "Please check with your teacher about absences over 3 weeks. Thank you"
              );
            }
          });
        } else {
          toast(
            "Absenz kann nicht mehr akzeptiert werden! Bitte immer vor 16:00Uhr eintragen. Vielen Dank!"
          );
        }
      }
    }
  };

  useEffect(() => {
    getdata();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="place_absence_section">
      <ToastContainer />
      <div className="container-fluid">
        <div className="user_detail">
          <div className="place_absence_title">
            <h2>Place Your Absence</h2>
          </div>
        </div>
        <div className="place_absence">
          <div className="place_absence_inner">
            <div className="place_absence_cards row">
              {Object.values(data)?.map(
                (item, index) =>
                  Object.values(item)[0].course_id && (
                    <div
                      className="place_absence_card col-xxl-4 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                      key={index}
                    >
                      <div className="place_absence_card_inner">
                        <form className="row">
                          <div className="col-12">
                            <div className="form-check place_absence_card_title">
                              <h2>
                                {Object.keys(data)[index] === "Normal"
                                  ? localStorage.getItem("session_language") ===
                                    "en"
                                    ? Object.keys(data)[index]
                                    : "Hauptkurs"
                                  : Object.keys(data)[index] === "Fixed"
                                  ? localStorage.getItem("session_language") ===
                                    "en"
                                    ? Object.keys(data)[index]
                                    : "Fixer Zusatzkurs"
                                  : Object.keys(data)[index] === "One Time"
                                  ? localStorage.getItem("session_language") ===
                                    "en"
                                    ? Object.keys(data)[index]
                                    : "Einmal"
                                  : Object.keys(data)[index]}
                              </h2>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="gridCheck"
                                checked={Object.values(item)
                                  ?.map((itemData) =>
                                    Number(itemData.course_id)
                                  )
                                  .every((elements) => {
                                    return dataToAdd.includes(elements);
                                  })}
                                onChange={() => {
                                  let currentArray = Object.values(item)?.map(
                                    (itemData) => Number(itemData.course_id)
                                  );

                                  const check = currentArray.every(
                                    (elements) => {
                                      return dataToAdd.includes(elements);
                                    }
                                  );

                                  let removedArray = dataToAdd?.filter(
                                    (element) =>
                                      !currentArray.includes(element) && element
                                  );

                                  let AllArray = [];
                                  Object.values(item)?.map(
                                    (itemData) =>
                                      (AllArray = !dataToAdd.includes(
                                        Number(itemData.course_id)
                                      )
                                        ? [
                                            ...AllArray,
                                            Number(itemData.course_id),
                                          ]
                                        : AllArray)
                                  );

                                  if (check) {
                                    setDataToAdd(removedArray);
                                  } else {
                                    setDataToAdd(removedArray);
                                    setDataToAdd(dataToAdd.concat(AllArray));
                                  }
                                }}
                              />
                            </div>
                          </div>
                          {Object.values(item).map((innerItem, innerIndex) => (
                            <div className="col-12" key={innerIndex}>
                              <div className="place_absence_detail">
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    checked={
                                      dataToAdd.includes(
                                        Number(innerItem.course_id)
                                      ) && true
                                    }
                                    onChange={() => {
                                      !dataToAdd.includes(
                                        Number(innerItem.course_id)
                                      )
                                        ? setDataToAdd([
                                            ...dataToAdd,
                                            Number(innerItem.course_id),
                                          ])
                                        : setDataToAdd(
                                            dataToAdd.filter(function (value) {
                                              return (
                                                value !==
                                                Number(innerItem.course_id)
                                              );
                                            })
                                          );
                                    }}
                                  />
                                  <label className="form-check-label">
                                    {innerItem.course_title}
                                  </label>
                                </div>
                              </div>
                            </div>
                          ))}
                        </form>
                      </div>
                    </div>
                  )
              )}
            </div>
            <div className="place_absence_calender">
              <form className="row">
                <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12">
                  <label htmlFor="inputStartdate" className="form-label">
                    SELECT <span>START</span> DATE
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    id="inputStartdate"
                    value={startDate}
                    onChange={(e) => {
                      setStartDate(e.target.value);
                    }}
                  />
                </div>
                <div className="col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12">
                  <div className="arrow_img">
                    <img className="img-fluid" src={right} alt="" />
                  </div>
                </div>
                <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12">
                  <label htmlFor="inputStartdate" className="form-label">
                    SELECT <span>END</span> DATE
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    id="inputStartdate"
                    value={endDate}
                    onChange={(e) => {
                      setEndDate(e.target.value);
                    }}
                  />
                </div>
              </form>
            </div>
            <div className="absent_btn mt-5">
              <Link className="btn btn_primary" onClick={placeAbsence}>
                ABSENT
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlaceAbsence;
